import { isEmpty } from 'lodash';
import { makeAutoObservable } from 'mobx';
import { post } from '../lib/request';

class UserStore {
    nickname = '';
    id = '';
    avatar = '';
    email = '';
    isLogin = false;
    initPromise = this.initUserInfo();
    constructor() {
        makeAutoObservable(this);
    }
    async initUserInfo() {
        const res = await post({
            url: '/api/user/info',
        });
        if (isEmpty(res) || isEmpty(res.result)) {
            return;
        }
        this.setUserInfo(res.result);
    }
    setUserInfo(userInfo) {
        if (isEmpty(userInfo)) {
            this.nickname = '';
            this.id = '';
            this.avatar = '';
            this.isLogin = false;
            return;
        }
        this.nickname = userInfo.nickname;
        this.id = userInfo.id;
        this.avatar = userInfo.avatar;
        this.isLogin = true;
    }
}
export const userStore = new UserStore();
